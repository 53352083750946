import AccountNotificationIcon from "@/assets/svg/nav/account_notifications.svg"
import AdminIcon from "@/assets/svg/nav/admins.svg"
import BrandIcon from "@/assets/svg/nav/brands.svg"
import CategoryIcon from "@/assets/svg/nav/categories.svg"
import CountryIcon from "@/assets/svg/nav/countries.svg"
import CourtPlaceTypeIcon from "@/assets/svg/nav/court_place_types.svg"
import CourtProviderIcon from "@/assets/svg/nav/court_providers.svg"
import CourtIcon from "@/assets/svg/nav/courts.svg"
import CurrencyIcon from "@/assets/svg/nav/currencies.svg"
import DashboardIcon from "@/assets/svg/nav/dashboard.svg"
import GenerateReportIcon from "@/assets/svg/nav/generate_report.svg"
import HomePageIcon from "@/assets/svg/nav/home_page.svg"
import IssueIcon from "@/assets/svg/nav/issues.svg"
import LocaleIcon from "@/assets/svg/nav/locales.svg"
import MediaIcon from "@/assets/svg/nav/media.svg"
import MessageIcon from "@/assets/svg/nav/messages.svg"
import PrivacyPolicyIcon from "@/assets/svg/nav/privacy_policy.svg"
import ProductIcon from "@/assets/svg/nav/products.svg"
import ReportedIssueIcon from "@/assets/svg/nav/reported_issues.svg"
import RoleIcon from "@/assets/svg/nav/roles.svg"
import SentNotificationIcon from "@/assets/svg/nav/send_notification.svg"
import SettingsIcon from "@/assets/svg/nav/settings.svg"
import SkillLevelIcon from "@/assets/svg/nav/skill_levels.svg"
import TermsCondIcon from "@/assets/svg/nav/terms_cond.svg"
import UserIcon from "@/assets/svg/nav/users.svg"
import ViewReportIcon from "@/assets/svg/nav/view_report.svg"
import ZoneIcon from "@/assets/svg/nav/zones.svg"

let adminMenu = [
  // Report
  { heading: 'reports' },
  {
    title: 'dashboard',
    to: { name: 'index' },
    icon: { icon: DashboardIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'dashboard.get',
  },
  {
    title: 'generateReports',
    to: { name: 'generate-report' },
    icon: { icon: GenerateReportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'report_query',
  },
  {
    title: 'viewReports',
    to: { name: 'view-report' },
    icon: { icon: ViewReportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'report_view',
  },

  // Operations
  { heading: 'operations' },
  {
    title: 'reportedIssues',
    to: { name: 'issues' },
    icon: { icon: ReportedIssueIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'issue',
  },
  {
    title: 'predefinedIssues',
    to: { name: 'predefined-issues' },
    icon: { icon: IssueIcon },
    is_custom: true,
    perm: 'predefined_issue',
  },
  {
    title: 'messages',
    to: { name: 'contact-us' },
    icon: { icon: MessageIcon },
    is_custom: true,
    perm: 'contact_us',
  },
  
  // Product
  { heading: 'courtsAndProducts' },
  {
    title: 'courtProviders',
    to: { name: 'court-providers' },
    icon: { icon: CourtProviderIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'court',
  },
  {
    title: 'courts',
    to: { name: 'courts' },
    icon: { icon: CourtIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'court',
  },
  {
    title: 'products',
    to: { name: 'products' },
    icon: { icon: ProductIcon },
    is_custom: true,
    perm: 'product',
  },

  // Notification
  { heading: 'notification' },
  {
    title: 'accountNotifications',
    to: { name: 'notifications' },
    icon: { icon: AccountNotificationIcon },
    is_custom: true,
  },
  {
    title: 'sendNotifications',
    to: { name: 'send-notifications' },
    icon: { icon: SentNotificationIcon },
    is_custom: true,
    perm: 'send_notification',
  },

  // accounts
  { heading: 'accounts' },
  {
    title: 'admins',
    to: { name: 'admins' },
    icon: { icon: AdminIcon },
    is_custom: true,
    perm: 'admin',
  },
  {
    title: 'users',
    to: { name: 'users' },
    icon: { icon: UserIcon },
    is_custom: true,
    perm: 'user',
  },
  {
    title: 'rolesAndPermissions',
    to: { name: 'roles-and-permissions' },
    icon: { icon: RoleIcon },
    is_custom: true,
    perm: 'role',
  },

  // Configurations
  { heading: 'configurations' },
  {
    title: 'locations',
    to: { name: 'locations' },
    icon: { icon: ZoneIcon },
    is_custom: true,
    perm: 'location',
  },
  {
    title: 'mediaLibrary',
    to: { name: 'media' },
    icon: { icon: MediaIcon },
    is_custom: true,
    perm: 'attachment',
  },

  // page builder
  { heading: 'pageBuilder' },
  {
    title: 'homePage',
    to: { name: 'home-page' },
    icon: { icon: HomePageIcon },
    is_custom: true,
    perm: 'home_page.get',
  },
  {
    title: 'privacyPolicy',
    to: { name: 'privacy-policy' },
    icon: { icon: PrivacyPolicyIcon },
    is_custom: true,
    perm: 'settings.privacy_policy',
  },
  {
    title: 'termsAndCondition',
    to: { name: 'terms-cond' },
    icon: { icon: TermsCondIcon },
    is_custom: true,
    perm: 'settings.terms_cond',
  },

  // settings
  { heading: 'settings' },
  {
    title: 'courtPlaceTypes',
    to: { name: 'court-place-types' },
    icon: { icon: CourtPlaceTypeIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'courtPlaceType',
  },
  {
    title: 'skillLevels',
    to: { name: 'skill-levels' },
    icon: { icon: SkillLevelIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'skillLevel',
  },
  {
    title: 'categories',
    to: { name: 'categories' },
    icon: { icon: CategoryIcon },
    is_custom: true,
    perm: 'category',
  },
  {
    title: 'brands',
    to: { name: 'brands' },
    icon: { icon: BrandIcon },
    is_custom: true,
    perm: 'brand',
  },
  {
    title: 'currencies',
    to: { name: 'currencies' },
    icon: { icon: CurrencyIcon },
    is_custom: true,
    perm: 'currency',
  },
  {
    title: 'locales',
    to: { name: 'locales' },
    icon: { icon: LocaleIcon },
    is_custom: true,
    perm: 'locale',
  },
  {
    title: 'countries',
    to: { name: 'countries' },
    icon: { icon: CountryIcon },
    is_custom: true,
    perm: 'country',
  },
  {
    title: 'settings',
    to: { name: 'settings' },
    icon: { icon: SettingsIcon },
    is_custom: true,
    perm: 'settings.general_settings',
  },

]

export default adminMenu
