<script setup>
import WebsiteAnalytics from '@/components/dashboard/WebsiteAnalytics.vue'
import DateRangeSelector from "@/components/selectors/DateRangeSelector.vue"
import { useDashboardStore } from "@/stores/dashboard"
import { useRoleStore } from "@/stores/role"
import { useSnackBarStore } from "@/stores/snackbar"
import RangeDateType from '@/constants/RangeDateType'
import { reactive } from "vue"

const roleStore = useRoleStore()
const dashboardStore = useDashboardStore()
const notification = useSnackBarStore()

const data = reactive({
  dashboard: {},
  dashboardLoading: false,
  filters: {
    date_range: RangeDateType.ALL.value,
  },
})

onMounted(async () => {
  getDashboardData()
})

const getDashboardData = () => {
  if( !roleStore.hasPerms('dashboard', 'get') ) {
    return
  }

  data.dashboardLoading = true
  dashboardStore.getDashboardData(data.filters)
    .then(res => {
      data.dashboard = res.data.data
    })
    .catch(notification.responseError)
    .finally(() => {
      data.dashboardLoading = false
    })
}

const selectDateRange = date => {
  data.filters.date_range = date
  getDashboardData()
}
</script>

<template>
  <ProgressBar v-if="data.dashboardLoading" />
  
  <div v-else>
    <VRow>
      <VCol
        cols="auto"
        class="mx-5"
      >
        <h3 class="pt-2">
          {{ $t('dashboard') }} 
          <VBtn
            icon="mdi-refresh"
            color="default"
            variant="text"
            class="ml-2"
            size="small"
            @click="getDashboardData"
          />
        </h3>
      </VCol>
      <VSpacer />
      <VCol
        cols="12" 
        sm="auto"
      >
        <DateRangeSelector
          :date-range="data.filters.date_range"
          @select-date-range="selectDateRange"
        />
      </VCol>
    </VRow>
    <VRow class="match-height">
      <VCol
        cols="12"
        md="6"
      >
        <WebsiteAnalytics 
          :total-customers="data.dashboard.total_clients"
          :total-active-issues="data.dashboard.total_reported_issues"
        />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/libs/apex-chart.scss";
</style>
